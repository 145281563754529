import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Typography,
  Modal,
} from "antd";
import { CheckCircleOutlined, SmileOutlined } from "@ant-design/icons";
import logo from "../../../assets/images/logo-dark.png";
import useHttp from "../../../components/Hook/Use-http";
import CONSTANT from "../../Utility/constnt";
import { useNavigate, useSearchParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { replaceId } from "../../Utility/function";
import { Alert } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Title, Text } = Typography;

const SignupPage = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const API_CALL = useHttp();
  const navigate = useNavigate();
  const [corporateSame, setCorporateSame] = useState(false);
  const [searchParams] = useSearchParams(); // Destructure the array to get searchParams
  const referral = searchParams.get("referral");
  const [initialData, setInitialData] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [gstDetails, setgstDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isClientLogin, setIsClientLogin] = useState(false);
  const [gstNumber, setgstNumber] = useState(null);
  const userRole = localStorage.getItem("userRole");
  const [isRegister, setIsRegister] = useState(false);
  const token = localStorage.getItem("token");
  const [clientId, setClientId] = useState(null);
  const [isReferralClicked, setIsReferralClicked] = useState(false);

  useEffect(() => {
    const signupData = async () => {
      if (userRole && userRole === "client") {
        let clientId;
        setIsClientLogin(true);
        try {
          await API_CALL.sendRequest(CONSTANT.API.ADMIN.getMe, (res) => {
            if (res) {
              clientId = res?.data?.clientId;
            }
          });
        } catch (error) {
          console.error("Error getting while fetching user", error);
        }

        if (clientId) {
          API_CALL.sendRequest(
            replaceId(CONSTANT.API.CLIENT.getSingleClient, clientId),
            (res) => {
              setIsRegister(res?.data[0]?.isRegistered);
              let clientData = res?.data[0];
              form.setFieldsValue({
                gstin: clientData.gstin,
                name: clientData.name,
                sector: clientData.sector,
                state: clientData.registeredState,
                website: clientData.website,
                cin: clientData.cin,
                ciiMembershipNo: clientData.ciiMembershipNo,
                registeredAddress1: clientData.registeredAddress1,
                registeredAddress2: clientData.registeredAddress2,
                registeredState: clientData.registeredState,
                registeredDistrict: clientData.registeredDistrict,
                registeredPincode: clientData.registeredPincode,
                corporateAddress1: clientData.corporateAddress1,
                corporateAddress2: clientData.corporateAddress2,
                corporateDistrict: clientData.corporateDistrict,
                corporateState: clientData.corporateState,
                corporatePincode: clientData.corporatePincode,
                annualTurnover: clientData.turnover,
                yearOfIncorporation: clientData.yearOfIncorporation,
              });
              if (clientData.gstin) {
                setIsDisabled(true);
              }
            }
          );
        } else {
          console.error("No client exists");
        }
      }
    };
    signupData();
  }, []);

  useEffect(() => {
    if (userRole && userRole === "client" && isRegister) {
      navigate("/");
    } else if (userRole && userRole === "client" && !isRegister) {
      navigate("/signup");
    }
  }, [isRegister]);

  useEffect(() => {
    if (referral) {
      try {
        const decodedReferral = JSON.parse(atob(referral));
        setInitialData(decodedReferral);
        setIsReferralClicked(true);
      } catch (error) {
        console.error("Failed to decode referral parameter:", error);
      }
    }
  }, [referral]);

  const handleGstNumber = (value) => {
    setgstNumber(value);
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue({
        pocDesignation: initialData.pocDesignation,
        pocEmail: initialData.pocEmail,
        pocName: initialData.pocName,
        pocMobile: initialData.pocPhoneNumber,
        sector: initialData.sector,
        registeredAddress1: initialData.registeredAddress1,
        registeredAddress2: initialData.registeredAddress2,
        state: initialData.state,
        registeredState: initialData.state,
        registeredPincode: initialData.pincode,
        gstin: initialData.gstin,
        name: initialData.name,
      });
      if (
        initialData.pocDesignation ||
        initialData.pocEmail ||
        initialData.pocName ||
        initialData.pocMobile ||
        initialData.sector ||
        initialData.state ||
        initialData.registeredAddress1 ||
        initialData.registeredAddress2 ||
        initialData.gstin
      ) {
        setIsDisabled(true); // Disable fields if there's data
      }
    }
  }, [initialData, form]);

  useEffect(() => {
    if (gstDetails) {
      form.setFieldsValue({
        name: gstDetails?.trade_name,
        registeredAddress1: gstDetails?.address?.door_number,
        registeredAddress2: gstDetails?.address?.city,
        registeredState: gstDetails?.address?.state_name,
        registeredPincode: gstDetails?.address?.pincode,
        state: gstDetails?.address?.state_name,
      });
    }
  }, [gstDetails]);

  const handleFinish = async (values) => {
    const formData = values;
    if (initialData || isClientLogin) {
      const res = await API_CALL.sendRequest(
        CONSTANT.API.ADMIN.register,
        (res) => {
          if (res) {
            setIsModalVisible(true);
            form.resetFields();
            navigate("/");
          }
        },
        formData,
        "Registered successfully"
      );
    } else {
      const res = await API_CALL.sendRequest(
        CONSTANT.API.ADMIN.signup,
        (res) => {
          if (res) {
            setIsModalVisible(true);
            form.resetFields();
          }
        },
        formData,
        "Signed up successfully"
      );
    }
  };

  const handleSameAddress = (e) => {
    const isChecked = e.target.checked;
    setCorporateSame(isChecked);
    if (isChecked) {
      form.setFieldsValue({
        corporateAddress1: form.getFieldValue("registeredAddress1"),
        corporateAddress2: form.getFieldValue("registeredAddress2"),
        corporateState: form.getFieldValue("registeredState"),
        corporateDistrict: form.getFieldValue("registeredDistrict"),
        corporatePincode: form.getFieldValue("registeredPincode"),
      });
    } else {
      form.setFieldsValue({
        corporateAddress1: "",
        corporateAddress2: "",
        corporateState: "",
        corporateDistrict: "",
        corporatePincode: "",
      });
    }
  };
  const handleValuesChange = (name, value) => {
    if (corporateSame) {
      switch (name) {
        case "registeredAddress1":
          form.setFieldsValue({ corporateAddress1: value });
          break;
        case "registeredAddress2":
          form.setFieldsValue({ corporateAddress2: value });
          break;
        case "registeredState":
          form.setFieldsValue({ corporateState: value });
          break;
        case "registeredDistrict":
          form.setFieldsValue({ corporateDistrict: value });
          break;
        case "registeredPincode":
          form.setFieldsValue({ corporatePincode: value });
          break;
        default:
          break;
      }
    }
  };
  const verifyGST = async () => {
    setLoading(true);
    try {
      //   CONSTANT.API.TRANSPORTER.addTransporterVerifyByGst,
      await API_CALL.sendRequest(
        CONSTANT.API.PUBLIC.getGstDetails,
        (res) => {
          if (res?.data?.Succeeded) {
            setgstDetails(res?.data?.Succeeded?.Gst_Details?.result);
          }
        },
        { GSTN: gstNumber }
      );
    } catch (error) {
      console.error(`Error verifying GST: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/Truck_wallpaper.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          width: "60%",
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src={logo} alt="Dummy Logo" style={{ maxWidth: "300px" }} />
        </div>
        {isReferralClicked && (
          <div style={{ textAlign: "center", marginBottom: "30px" }}>
            <Alert
              // message="Please login first"
              description="Please login with your registered email followed by an OTP, to complete registration of your organization"
              type="warning"
              showIcon
              icon={<ExclamationCircleOutlined />}
              style={{ fontSize: "18px" }}
            />
          </div>
        )}
        <Title level={3} style={{ textAlign: "center", marginBottom: "30px" }}>
          Register
        </Title>

        <Form form={form} layout="vertical" onFinish={handleFinish}>
          {/* Organization Information Section */}
          <Title level={4}>Organization Information</Title>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="GSTN"
                name="gstin"
                rules={[{ required: true, message: "Please input your GSTN!" }]}
              >
                <Input
                  placeholder="Enter GSTN"
                  onChange={(e) => handleGstNumber(e.target.value)}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button
                  key="verify"
                  type="primary"
                  loading={loading}
                  onClick={verifyGST}
                  disabled={isDisabled}
                  style={{
                    marginTop: "25px",
                    backgroundColor: "#060DBF",
                    color: "#62C941",
                  }}
                >
                  verify
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Name of Organization"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your organization name!",
              },
            ]}
          >
            <Input placeholder="Enter organization name" />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Sector"
                name="sector"
                rules={[
                  { required: true, message: "Please select your sector!" },
                ]}
              >
                <Select placeholder="Select sector">
                  <Option value="agriculture">Agriculture</Option>
                  <Option value="automobile">Automobile</Option>
                  <Option value="banking">Banking & Financial Services</Option>
                  <Option value="construction">Construction</Option>
                  <Option value="education">Education</Option>
                  <Option value="healthcare">Healthcare</Option>
                  <Option value="it">Information Technology</Option>
                  <Option value="manufacturing">Manufacturing</Option>
                  <Option value="pharmaceuticals">Pharmaceuticals</Option>
                  <Option value="retail">Retail</Option>
                  <Option value="telecom">Telecommunications</Option>
                  <Option value="textile">Textile</Option>
                  <Option value="transportation">
                    Transportation & Logistics
                  </Option>
                  <Option value="energy">Energy & Utilities</Option>
                  <Option value="realEstate">Real Estate</Option>
                  <Option value="others">Others</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Website URL"
                name="website"
                rules={[
                  { required: true, message: "Please input your website URL!" },
                ]}
              >
                <Input placeholder="Enter website URL" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  { required: true, message: "Please select your state!" },
                ]}
              >
                <Input placeholder="Please enter state" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Year of Incorporation"
                name="yearOfIncorporation"
                rules={[
                  {
                    required: true,
                    message: "Please input the year of incorporation!",
                  },
                  {
                    pattern: /^(20\d{2})$/, // Matches either "YYYY" or "YYYY-YY"
                    message: "Please input a valid year (YYYY)!",
                  },
                ]}
              >
                <Input placeholder="Enter year of incorporation" />
              </Form.Item>
            </Col>
          </Row>
          {/* <Form.Item
            label="Cluster"
            name="cluster"
            rules={[{ required: true, message: 'Please input your cluster!' }]}
          >
            <Input placeholder="Enter cluster" />
          </Form.Item> */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="CIN Number"
                name="cin"
                rules={[
                  {
                    required: true,
                    message: "Please input your CIN Number!",
                  },
                ]}
              >
                <Input placeholder="Enter CIN number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="CII Membership Number"
                name="ciiMembershipNo"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input your CII Membership Number!",
                //   },
                // ]}
              >
                <Input placeholder="Enter CII membership number" />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <Title level={4}>Address Information</Title>
            <Checkbox onChange={handleSameAddress} value={corporateSame}>
              Corporate address same as registered address
            </Checkbox>
          </div>

          {/* Address Information Section */}
          {/* <Title level={4} style={{ marginTop: '30px' }}>Address Information</Title> */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Registered Address 1"
                name="registeredAddress1"
                rules={[
                  {
                    required: true,
                    message: "Please input your registered office address!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter registered office address"
                  onChange={(e) =>
                    handleValuesChange("registeredAddress1", e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                label="Registered Address 2"
                name="registeredAddress2"
                rules={[
                  {
                    required: true,
                    message: "Please input your registered office address!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter registered office address"
                  onChange={(e) =>
                    handleValuesChange("registeredAddress2", e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                label="Registered state"
                name="registeredState"
                rules={[
                  { required: true, message: "Please select your state!" },
                ]}
              >
                <Input
                  placeholder="Enter registered state"
                  onChange={(e) =>
                    handleValuesChange("registeredState", e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                label="Registered district"
                name="registeredDistrict"
                rules={[
                  { required: true, message: "Please input your district!" },
                ]}
              >
                <Input
                  placeholder="Enter registered district"
                  onChange={(e) =>
                    handleValuesChange("registeredDistrict", e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                label="Registered pincode"
                name="registeredPincode"
                rules={[
                  { required: true, message: "Please input your pincode!" },
                ]}
              >
                <Input
                  placeholder="Enter registered pincode"
                  onChange={(e) =>
                    handleValuesChange("registeredPincode", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* <Checkbox onChange={handleSameAddress}>Corporate address same as registered address</Checkbox> */}
              <Form.Item
                label="Corporate address 1"
                name="corporateAddress1"
                rules={[
                  {
                    required: true,
                    message: "Please input your corporate office address!",
                  },
                ]}
              >
                <Input placeholder="Enter corporate office address" />
              </Form.Item>
              <Form.Item
                label="Corporate address 2"
                name="corporateAddress2"
                rules={[
                  {
                    required: true,
                    message: "Please input your corporate office address!",
                  },
                ]}
              >
                <Input placeholder="Enter corporate office address" />
              </Form.Item>
              <Form.Item
                label="Corporate state"
                name="corporateState"
                rules={[
                  { required: true, message: "Please select your state!" },
                ]}
              >
                <Input placeholder="Enter corporate state" />
              </Form.Item>
              <Form.Item
                label="Corporate district"
                name="corporateDistrict"
                rules={[
                  { required: true, message: "Please input your district!" },
                ]}
              >
                <Input placeholder="Enter corporate district" />
              </Form.Item>
              <Form.Item
                label="Corporate pincode"
                name="corporatePincode"
                rules={[
                  { required: true, message: "Please input your pincode!" },
                ]}
              >
                <Input placeholder="Enter corporate pincode" />
              </Form.Item>
            </Col>
          </Row>

          {/* Contact Person Details Section */}
          {!isClientLogin && (
            <div>
              <Title level={4} style={{ marginTop: "30px" }}>
                Contact Person Details
              </Title>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Name"
                    name="pocName"
                    rules={[
                      {
                        required: true,
                        message: "Please input the contact person's name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter name" disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Designation"
                    name="pocDesignation"
                    rules={[
                      {
                        required: true,
                        message: "Please input the designation!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter designation"
                      disabled={isDisabled}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="pocEmail"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please input a valid email!",
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        validator: (_, value) => {
                          const domain = value ? value.split("@")[1] : "";
                          if (
                            ["gmail.com", "yahoo.com", "hotmail.com"].includes(
                              domain
                            )
                          ) {
                            return Promise.reject(
                              new Error(
                                "Please use your corporate email address!"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Enter email" disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Mobile"
                    name="pocMobile"
                    rules={[
                      {
                        required: true,
                        message: "Please input the mobile number!",
                      },
                    ]}
                  >
                    <PhoneInput
                      country={"in"}
                      enableSearch={true}
                      containerStyle={{ width: "100%" }}
                      inputStyle={{ width: "100%" }}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      disabled={isDisabled}
                    />
                    {/* <Input placeholder="Enter mobile number" disabled={isDisabled} /> */}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {/* Annual Financial Turnover Section */}
          <Title level={4} style={{ marginTop: "30px" }}>
            Annual Financial Turnover
          </Title>
          <Row gutter={16}>
            <Col span={7}>
              <Form.Item
                label="Annual Turnover"
                name="annualTurnover"
                rules={[
                  {
                    required: true,
                    message: "Please input your annual turnover!",
                  },
                  // { type: 'number', message: 'Annual turnover must be a number!' },
                ]}
              >
                <Input
                  placeholder="Enter annual turnover"
                  type="number"
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Number of Employees"
                name="numberOfEmployees"
                rules={[
                  {
                    required: true,
                    message: "Please input the number of employees!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter number of employees"
                  type="number"
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Year"
                name="annualTurnoverfinancialYear"
                rules={[
                  {
                    required: true,
                    message: "Please input the financial year!",
                  },
                  {
                    pattern: /^(20\d{2})-(\d{2})$/, // Example pattern for financial year format (YYYY)
                    message: "Please input a valid financial year (YYYY)!",
                  },
                ]}
              >
                <Input placeholder="Enter financial year" />
              </Form.Item>
            </Col>
          </Row>

          {/* Submit Button */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{
                backgroundColor: "#060DBF",
                color: "#62C941",
              }}
              disabled={isReferralClicked}
            >
              Register
            </Button>
          </Form.Item>
        </Form>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          {isClientLogin && (
            <>
              <Text>Want to logout? </Text>
              <a href="/logout" style={{ color: "#1890ff" }}>
                Logout
              </a>
            </>
          )}
        </div>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          {!isClientLogin && (
            <>
              <Text>Already have an account? </Text>
              <a href="/login" style={{ color: "#1890ff" }}>
                Go to Login
              </a>
            </>
          )}
        </div>
      </div>

      {/* Thank You Modal */}
      <Modal
        title={
          <Title level={3} style={{ textAlign: "center" }}>
            Thank You!
          </Title>
        }
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button
            key="close"
            type="primary"
            onClick={handleModalClose}
            style={{
              backgroundColor: "#060DBF",
              color: "#62C941",
            }}
          >
            Close
          </Button>,
        ]}
        centered
        style={{ textAlign: "center" }}
      >
        <CheckCircleOutlined style={{ fontSize: "50px", color: "#52c41a" }} />
        <p style={{ fontSize: "16px", marginTop: "20px" }}>
          Your registration has been submitted successfully!
        </p>
        {/* <SmileOutlined style={{ fontSize: '40px', color: '#52c41a', marginTop: '10px' }} /> */}
      </Modal>
    </div>
  );
};

export default SignupPage;
