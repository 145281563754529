import React from "react";
import { Col, Row } from "reactstrap";

const DateFilterCard = (props) => {
  const { extraValue } = props;
  return (
    <>
      <Row>
        <Col className="col-12 OverFlowScroll">
          <div className="card p-4">
            <form onSubmit={extraValue?.handlerFormSubmission}>
              <div className="row">
                <div className="form-group col-12 col-lg-6">
                  <label className="mb-2 ">
                    <strong>Start Date</strong>
                  </label>
                  <input
                    id="startDate"
                    type="date"
                    className="form-control"
                    placeholder="Enter Start Date"
                    autoComplete="off"
                    required={true}
                  />
                </div>
                <div className="form-group col-12 col-lg-6">
                  <label className="mb-2 ">
                    <strong>End Date</strong>
                  </label>
                  <input
                    type="date"
                    id="endDate"
                    className="form-control"
                    placeholder="Enter End Date"
                    autoComplete="off"
                    required={true}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center"
                style={{
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <button
                  type="submit"
                  className="btn btn-xs btn-primary mr-2"
                  title="Submit"
                  disabled={extraValue?.SEARCH_API_CALL.isLoading}
                >
                  Submit
                </button>
                <button
                  type="reset"
                  className="btn btn-xs btn-primary "
                  title="Reset"
                  onClick={() => extraValue?.setFilterParams("")}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DateFilterCard;
